let prefixer;

const hostname = window.location.hostname;

switch (hostname) {
  case process.env.REACT_APP_PRODUCTION_HOST:
    prefixer = process.env.REACT_APP_PRODUCTION_API_URL;
    break;
  case process.env.REACT_APP_STAGING_HOST:
    prefixer = process.env.REACT_APP_STAGING_API_URL;
    break;
  case process.env.REACT_APP_DEV_HOST:
    prefixer = process.env.REACT_APP_DEV_API_URL;
    break;
  default:
    prefixer = process.env.REACT_APP_LOCAL_API_URL;
}

export interface GlobalPaginate {
  page?: number;
  per_page?: number;
  keyword?: string;
}

export const AuthUrls = {
  PASSWORD_LOGIN: `${prefixer}/auth/local`,
  GET_CONNECTED_INFO: `${prefixer}/users/me`,
  RESET_PASSWORD_INIT: `${prefixer}/user/forgot-password`,
  RESET_PASSWORD: `${prefixer}/user/reset-password`,
  CREATE_PASSWORD: `${prefixer}/user/create-password`,
  REFRESH_TOKEN: `${prefixer}/auth/refresh-token`,
};
